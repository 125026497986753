// This file is part of Materials Cloud Archive
// Copyright (C) 2025 Materials Cloud Team.

/**
 * Overridden components of upload form.
 */
import { i18next } from "@translations/invenio_rdm_records/i18next";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import {
  AccordionField,
  FieldLabel,
  RichInputField,
  TextField
} from "react-invenio-forms";
import {
  FileUploader,
  FormFeedback,
  PreviewButton,
  PublishButton,
  ResourceTypeField,
  SaveButton,
} from "@js/invenio_rdm_records";
import { ShareDraftButton } from "@js/invenio_app_rdm/deposit/ShareDraftButton";
import { CreatibutorsField } from "./MCACreatibutorsField";
import { DepositStatusBox } from "./MCADepositStatusBox";
import { SubjectsField } from "./MCASubjectsField";

import { Card, Grid, Icon, Message } from "semantic-ui-react";
import _get from "lodash/get";
import currentDate from "./todayDate";

const defaultLabels = {
  "metadata.resource_type": "Resource type",
  "metadata.creators": "Authors",
  "metadata.rights": "Licenses",
  "metadata.languages": "Languages",
  "metadata.dates": "Dates",
  "metadata.version": "Version",
  "metadata.publisher": "Publisher",
  "metadata.identifiers": "Alternate identifiers",
  "metadata.subjects": "Keywords",
  "access.embargo.until": "Embargo until",
  "pids.doi": "DOI",
  "custom_fields.mc_sections": "Materials Cloud sections using these data",
  "custom_fields.mc_references": "References",
};

// Form feedback
const mapStateToPropsFormFeedback = (state) => ({
  actionState: state.deposit.actionState,
  record: state.deposit.record,
  errors: state.deposit.errors,
});

const FormFeedbackComp = (props) => {
  const labels = {
    ...defaultLabels,
    ...props.labels,
  };

  if (props.actionState == 'DRAFT_PUBLISH_FAILED'
      && props.record.is_published == true
      && props.errors.message == 'Permission denied.'
  ) {
    props.errors.message = "Permission denied.";
  }

  // Add warning if trying to change published record
  if (props.record.is_published && props.record.status == "published") {
    return (
      <>
        <p className="pt-5">
        <Message warning visible>
          <p className="mt-5 display-inline-block">
            <Icon name="warning circle" size="large" />
              This is a draft of your published record. You can update 'Keywords', 'References' or 'Materials Cloud sections using these data' and directly publish these changes.<br/>
              <div style={{"padding-left": "30px"}}>If you want to make changes to sections other than these, you must create a new version. Click on 'Discard changes' to delete this draft, then select your published record and click on 'New version'.</div>
          </p>
        </Message>
        </p>
        <FormFeedback
        fieldPath="message"
        labels={labels}
        />
      </>
    );
  } else {
    return (
      <FormFeedback
      fieldPath="message"
      labels={labels}
      />
    );
  }
};

const StateFormFeedbackComp = connect(
  mapStateToPropsFormFeedback,
  null
)(FormFeedbackComp);


const FormFeedbackComponent = () => {
  return(<StateFormFeedbackComp />);
};

// Files
const FileUploaderComponent = (props) => {
  return (
    <>
    <label htmlFor="file_uploader" className="helptext">
      Please add a files_description.md file with the description of each file uploaded in this record.<br/>
      Detailed contents should be described in a separate README.txt/.md/.rst file placed outside the archives.
    </label>
    <FileUploader id="file_uploader"
      isDraftRecord={!props.record.is_published}
      quota={props.config.quota}
      decimalSizeDisplay={props.config.decimal_size_display}
      showMetadataOnlyToggle={props.permissions?.can_manage_files}
      allowEmptyFiles={props.allowEmptyFiles}
      filesLocked={props.filesLocked}
    />
    </>
  );
};

// DOI component
const DOIComponent = (props) => {
    if (typeof props.record.pids.doi !== 'undefined') {
      const doi = props.record.pids.doi.identifier;
      if (doi) {
        return (
          <Fragment>
          <b>
          <FieldLabel icon={"barcode"} label={"Digital Object Identifier"} />
          </b>
          <br/><br/>
          <label>{doi}</label>
          <br/>
          <label class="helptext">{"The DOI will resolve only after the record has been reviewed and published by the Materials Cloud Archive moderators."}</label>
          </Fragment>
        );
      } else {
        return (<></>);
      }
    } else {
      return (<></>);
    }
};


// Resource type
const ResourceTypeFieldComponent = (props) => {
  // only dataset and software are allowed
  props.vocabularies.metadata.resource_type = props.vocabularies.metadata.resource_type.filter(function( obj ) {
    return (obj.id == 'dataset' || obj.id == 'software');
  });
  return (
    <ResourceTypeField
      options={props.vocabularies.metadata.resource_type}
      fieldPath="metadata.resource_type"
      required
    />
  );
}

// Title component
const TitleComponent = (props) => {
    const { fieldPath, label, required } = props;
    return (
      <>
        <TextField
          fieldPath={fieldPath}
          label={<FieldLabel htmlFor={fieldPath} icon="book" label={label} />}
          required={required}
          className="title-field"
          optimized
        />
      </>
    );
};

// Description component
const DescriptionComponent = (props) => {
    const { fieldPath, label, labelIcon, editorConfig } = props;
    return (
      <>
        <RichInputField
          className="description-field rel-mb-1"
          fieldPath={fieldPath}
          editorConfig={editorConfig}
          label={<FieldLabel htmlFor={fieldPath} icon={labelIcon} label={label} />}
          optimized
          required
        />
      </>
    );
};

// Authors component
const CreatibutorsComponent = (props) => {
  props.modal.addLabel = "Add author";
  props.modal.editLabel = "Edit author";
  props.vocabularies.metadata.creators.type = [{ text: "Person", value: "personal" }]
  props.vocabularies.metadata.contributors.type = [{ text: "Person", value: "personal" }]
  return (
    <CreatibutorsField
      addButtonLabel={i18next.t("Add author")}
      label={i18next.t("Authors")}
      labelIcon="user"
      fieldPath="metadata.creators"
      roleOptions={props.config.vocabularies.creators.role}
      schema="creators"
      autocompleteNames={props.config.autocomplete_names}
      required
    />
  );
};

// RecommendedInformationComponent/Keywords component
const RecommendedInformationComponent = (props) => {
  return (
    <AccordionField
      includesPaths={[
        "metadata.subjects",
      ]}
      active
      label="Keywords"
    >
    <div class="field">
      <FieldLabel htmlFor="metadata.subjects" icon="tag icon" label="Keywords" />
    </div>
    <label htmlFor="metadata.subjects" class="helptext">
        <p style={{ color: "red" }}>Insert minimum 3 keywords.<br/></p>
        Enter any keyword or choose from the suggested keywords as you type.
        If this work is funded by one of the Materials Cloud partners please use the section Funding to add the grant that identify the partner.
    </label>
    <SubjectsField
      fieldPath="metadata.subjects"
      label="Keywords"
      placeholder="Insert a keyword"
      initialOptions={_get(props.record, "ui.subjects", null)}
      limitToOptions={props.vocabularies.metadata.subjects.limit_to}
    />
    </AccordionField>
  );
};

// Side bar column
// Case draft is declined, do not display buttons Save/Preview/Publish
const mapStateToProps = (state) => ({
    depositEditorState: state.deposit.editorState,
    depositStatus: state.deposit.record.status,
    depositPermission: state.deposit.permissions,
    depositRecord: state.deposit.record,
    depositMetadata: state.deposit.record.metadata,
    // depositgroupsEnabled: state.deposit.groupsEnabled,
});

const CardDepositStatusBoxComp = (props) => {
    const status = props.depositStatus;
    const record = props.depositRecord;
    const permissions = props.depositPermission;
    // const groupsEnabled = props.groupsEnabled;

    if (!status) {
        throw new Error("Status is undefined");
    }
    props.depositEditorState.ui.showCommunitySelectionButton = false;
    props.depositEditorState.ui.disableCommunitySelectionButton = true;
    props.depositEditorState.ui.showChangeCommunityButton = false;
    props.depositEditorState.ui.showCommunityHeader = false;

    if (status != "published") {
      props.depositEditorState.ui.showSubmitForReviewButton = true;
    }

    // show 'Submit for review' button if new version draft
    if (status == "new_version_draft") {
      // Needed to allow review of new versions
      props.depositEditorState.actions.communityStateMustBeChecked = true;
      props.depositEditorState.actions.shouldDeleteReview = false;
      props.depositEditorState.actions.shouldUpdateReview = true;

      // Publication date is missing in new version: need to add it
      props.depositMetadata.publication_date = currentDate.currentDate;
    }

    if (status !== "declined") {
        return (
            <Card>
            <Card.Content>
                <DepositStatusBox />
            </Card.Content>
            <Card.Content>
                <Grid relaxed>
                    <Grid.Column
                    computer={8}
                    mobile={16}
                    className="pb-0 left-btn-col"
                    >
                    <SaveButton fluid />
                    </Grid.Column>

                    <Grid.Column
                    computer={8}
                    mobile={16}
                    className="pb-0 right-btn-col"
                    >
                    <PreviewButton fluid />
                    </Grid.Column>

                    <Grid.Column width={16} className="pt-10">
                      <PublishButton fluid/>
                    </Grid.Column>

                    <Grid.Column width={16} className="pt-0">
                      {(record.is_draft === null || permissions.can_manage) && (
                        <ShareDraftButton
                          record={record}
                          permissions={permissions}
                          groupsEnabled={false}
                        />
                      )}
                    </Grid.Column>
                </Grid>
            </Card.Content>
            </Card>
        );
    }
    return(
        <Card>
        <Card.Content>
            <DepositStatusBox />
        </Card.Content>
        </Card>
    );
};

const StateToCardDepositStatusBoxComp = connect(
    mapStateToProps,
    null
  )(CardDepositStatusBoxComp);


const CardDepositStatusBoxComponent = () => {
    return(<StateToCardDepositStatusBoxComp />);
};

export {
    FormFeedbackComponent,
    FileUploaderComponent,
    DOIComponent,
    ResourceTypeFieldComponent,
    TitleComponent,
    DescriptionComponent,
    CreatibutorsComponent,
    RecommendedInformationComponent,
    CardDepositStatusBoxComponent,
};