// This file is part of Materials Cloud Archive
// Copyright (C) 2023 Materials Cloud Team.

/**
 * Overridden components.
 */

import React from "react";
import {
    FormFeedbackComponent,
    FileUploaderComponent,
    DOIComponent,
    ResourceTypeFieldComponent,
    TitleComponent,
    DescriptionComponent,
    CreatibutorsComponent,
    RecommendedInformationComponent,
    CardDepositStatusBoxComponent,
} from "../uploadForm.js";
import { MCARequestMetadataLayout } from "../MCARequestMetadataLayout.js";
import { MCARecordsResultsListItem } from "../MCARecordsResultsListItem.js";
import { MCARDMRecordResultsListItem } from "../user_dashboard/uploads.js";


const EmptyComponent = () => {
    return (<></>);
};

export const overriddenComponents = {
    "InvenioAppRdm.Deposit.FileUploader.container": FileUploaderComponent,
    "InvenioAppRdm.Deposit.FormFeedback.container": FormFeedbackComponent,
    "InvenioAppRdm.Deposit.PIDField.container": DOIComponent,
    "InvenioAppRdm.Deposit.ResourceTypeField.container": ResourceTypeFieldComponent,
    "InvenioAppRdm.Deposit.TitlesField.container": TitleComponent,
    "InvenioAppRdm.Deposit.DescriptionsField.container": DescriptionComponent,
    "InvenioAppRdm.Deposit.CreatorsField.container": CreatibutorsComponent,
    "InvenioAppRdm.Deposit.AccordionFieldRecommendedInformation.container": RecommendedInformationComponent,
    "InvenioAppRdm.Deposit.CardDepositStatusBox.container": CardDepositStatusBoxComponent,

    // overrides setting to empty these fields
    "ReactInvenioDeposit.FileUploaderToolbar.MetadataOnlyToggle.container": EmptyComponent,
    "InvenioAppRdm.Deposit.CommunityHeader.container": EmptyComponent,
    "InvenioAppRdm.Deposit.AccessRightField.container": EmptyComponent,
    "InvenioAppRdm.Deposit.AccordionFieldAlternateIdentifiers.container": EmptyComponent,
    "InvenioAppRdm.Deposit.AccordionFieldRelatedWorks.container": EmptyComponent,
    "InvenioAppRdm.Deposit.RelatedWorksField.container": EmptyComponent,
    "InvenioAppRdm.Deposit.AccordionFieldReferences.container": EmptyComponent,
    "InvenioAppRdm.Deposit.PublicationDateField.container": EmptyComponent,

    // overrides the item list in frontpage to remove DisplayPartOfCommunities and change the record link to record/<mcid>
    "InvenioAppRDM.RecordsList.RecordsResultsListItem.layout": MCARecordsResultsListItem,

    // overrides the item list in search to remove DisplayPartOfCommunities and change the record link to record/<mcid>
    "InvenioAppRdm.Search.RecordsResultsListItem.layout": MCARecordsResultsListItem,

    // overrides the item list in My records to remove DisplayPartOfCommunities
    "InvenioAppRdm.DashboardUploads.ResultsList.item": MCARDMRecordResultsListItem,

    // overrides the request My requests/Conversation to hide the Receiver community link
    "InvenioRequest.RequestMetadata.Layout": MCARequestMetadataLayout,
}
